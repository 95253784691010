import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { tap } from 'rxjs';

import { PermissionService } from '@emrm/permissions/service';

export const PermissionAppGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
) => {
	const router: Router = inject(Router);
	const permissionService: PermissionService = inject(PermissionService);
	const appCode = route.data['appCode'];

	return permissionService.hasAccessToApp$(appCode).pipe(
		tap((result) => {
			if (!result) {
				router.navigate(['/']);
			}
		}),
	);
};
